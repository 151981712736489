

function SubmitAlert(props) {

  return (
  
  <div className='container-fluid-lg'>
  
    { props.success ? <div className="container col-12 alert alert-success alert-dismissible" role="alert">
      <p className="mb-0">Your request has been <strong>sent</strong>.</p>
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div> : null }

    { props.failed ? <div className="container col-12 alert alert-danger" role="alert">
      <p>There was an issue with your request. Please <strong>try again</strong>.</p> 
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div> : null }
  
</div>

    )
}
export default SubmitAlert;