import { motion } from "framer-motion";


function IntersectionHeader() {

    const innerVariants = {
        hidden: {opacity: 0}, 
        show: {
            opacity: 1, 
            transition: { 
                duration: 1, 
                ease: 'easeOut', 
                delay: 0.2 }}} 
  
    return (
 
        <div className="container-fluid intersect-title p-5">

            <motion.h1 className="mainFont-header"
                        variants={innerVariants}
                        initial='hidden'
                        whileInView='show'>
                        Working <br/>
                        at the <br/>
                        Intersection of:
            </motion.h1>

        </div>
    );
  }
  
  export default IntersectionHeader;