import IntersectionHeader from "../Spacers/IntersectionHeader";
import { motion } from "framer-motion";



function IntersectionMain(props) {

    return (
 
    <div className="container-fluid intermain min-vh-100 center-text-div p-5">
          
        <IntersectionHeader/> 

      <motion.div className="container row p-5" variants={props.cVariants}
                  initial="hidden"
                  whileInView="show"> 
  

      <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 center-text-div p-2" 
                  variants={props.iVariants}>
          <div className="values-blue-sq center-text-div p-2">
              <h1 className="mainFont">Color</h1>
          </div>
      </motion.div>


      <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 center-text-div p-2" 
           variants={props.iVariants}>
          <div className="values-purp-sq center-text-div p-2">
              <h1 className="mainFont">Sound</h1>
          </div>
      </motion.div>
      

      <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 center-text-div p-2" 
           variants={props.iVariants}>
          <div className="values-mag-sq center-text-div p-2">
              <h1 className="mainFont">Psychology</h1>
          </div>
      </motion.div>
      
        
      <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 center-text-div p-2" 
           variants={props.iVariants}>
          <div className="values-org-sq center-text-div p-2">
              <h1 className="mainFont">Movement</h1>
          </div>
      </motion.div>
  
        </motion.div>
     
    </div>
       
    );
  }
  
  export default IntersectionMain;