import {AdvancedImage} from '@cloudinary/react';
import { motion } from "framer-motion";

function LandingVisual(props) {

  const subheaderVariants = {
    
    hidden: { opacity: 0 }, 
    show: {opacity: 1, 
    transition: {
        staggerChildren: 1}}}



    return (
 
        <div className="container-fluid-lg min-vh-100">
            <div className="bkrnd-visual-wrapper">
            
              <AdvancedImage className='bkrnd-visual_gif' cldImg={props.landingViz} />

              <div className="container card-img-overlay position-absolute center-text-div">

                  <h1 className="card-text landing-visual-header">
                          SFMERSIVE 
                          <br/>
                  </h1>

                  <motion.h2 className="card-text landing-visual-subheader"
                      variants={subheaderVariants}
                      initial="hidden"
                      whileInView="show">

                        <motion.span variants={props.iVariants} 
                                     style={{color: 'gold'}}>
                                    Multimedia Artist.&nbsp;
                        </motion.span>

                        <motion.span variants={props.iVariants} 
                                     style={{color: 'orange'}}>
                                    Technologist.&nbsp;
                        </motion.span>

                        <motion.span variants={props.iVariants} 
                                     style={{color: 'darkorange'}}>
                                    Speaker.
                        </motion.span>
                         
                  </motion.h2>
              </div>
        </div>
      </div> 
    );
  }
  
  export default LandingVisual;