import { motion } from "framer-motion";
import AboutPgraphChar from "./AboutPgraphChar";

function AboutParagraph({children, range, progress}) {


  let characters = children.split('');
  let amount = range[1] - range[0];
  const step = amount / children.length;
  
    return (
    <span className="aboutWords">
  <motion.span className="p-2">

     {characters.map((character, i) => {
      const start = range[0] + (step * i);
      const end = range[0] + (step * (i + 1));
      return <AboutPgraphChar key={i} range={[start, end]} progress={progress}>{character}</AboutPgraphChar> 
     })}
      </motion.span>
      </span>
    );
  }
  
  export default AboutParagraph;