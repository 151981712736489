import { motion, useTransform } from "framer-motion";

function AboutPgraphChar({children, range, progress}) {

  const opacity = useTransform(progress, range, [0,1])

  
    return (
    <span className="aboutWords">
      <span className="aboutWordsShadow">{children}</span>
  <motion.span className=""
        style={{opacity}}>
     {children}
      </motion.span>
      </span>
    );
  }

export default AboutPgraphChar;