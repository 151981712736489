
function WhiteBreak() {

    return (

      <div className="container-fluid-lg whitebreak">

      </div>

     
 
     
    );
  }
  
  export default WhiteBreak;