import NewsletterForm from "./Forms/NewsletterForm";

function Footer() {

    return (
    <div className='container-fluid-lg mainBg p-4'>
          <br/>

        <div className="row g-3">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-3">
          <p className="text-white">Copyright © 2024 SFMERSIVE.
            <br/> 
            All rights reserved. 
            </p>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-3">
            <NewsletterForm />
          </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-3">
          <p className="text-white">Terms and Conditions | Privacy Policy</p>
        </div> 

      </div>

       <br/>
      
    </div>   
    );
  }
  
  export default Footer;