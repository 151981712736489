import {AdvancedVideo, AdvancedImage} from '@cloudinary/react';
import { motion } from "framer-motion";


function CollaborateFor(props) {

    return (
 
      <div className="container-fluid collab-cont min-vh-100 p-5 center-text-div">

        <div className="row" >

            <div className="col-sm-12 col-md-12 col-lg-12 p-5">

                    <div className="container card mx-auto"
                        style={{ 
                        border: 'none', 
                        backgroundColor: 'transparent'}}>

                    <motion.div className="card-body"
                                initial={{ opacity:0 }} 
                                whileInView={{ opacity:1 }} 
                                transition={{ duration: 0.9, delay: 0.1 }} >
                        <h1 className="opacity-100 mainFont-header text-dark card-text wordkeep">
                                Collaborate with SFMERSIVE:
                        </h1>
                    </motion.div>
            </div>
        </div>


  <div className="container-fluid ">
    <div className="row">

    <div className="col-12 ">

        <div id="carouselExample" 
             className="carousel slide center-text-div"
             data-ride="carousel">

                <div className="carousel-inner p-5"> 

                    <div className="carousel-item active">
                        <motion.div className="row"
                              variants={props.cVariants}
                              initial="hidden"
                              whileInView="show">

                            <motion.div className="col-md-4 mb-3"
                                variants={props.iVariants}>

                                <div className="card border-0" >

                                <AdvancedVideo className='img-fluid card-img-top autoplay speakMain_img' 
                                               cldVid={props.collabVid1} muted loop />

                                <div className="card-body h-75">
                                    <h4 className="card-title">
                                            Video Editing
                                    </h4>
                                   
                                </div>
                                </div>
                            </motion.div>

                            <motion.div className="col-md-4 mb-3"
                                variants={props.iVariants}>
                                <div className="card border-0">

                                <AdvancedImage className='img-fluid card-img-top autoplay speakMain_img' 
                                               cldImg={props.collabSnd2} muted loop />

                                <div className="card-body">
                                     <h4 className="card-title">
                                     Audio Editing and Production
                                     </h4>
                                                
                                  </div>
                                  </div>
                                </motion.div>

                                <motion.div className="col-md-4 mb-3"
                                variants={props.iVariants}>
                                <div className="card border-0">

                                <AdvancedVideo className='img-fluid card-img-top autoplay speakMain_img' 
                                               cldVid={props.collabMulti3} muted loop />

                                

                                <div className="card-body">
                                     <h4 className="card-title">
                                      Multimedia Design
                                     </h4>
                                  </div>
                                  </div>
                              </motion.div>    
                        </motion.div>
                    </div>


                            <div className="carousel-item">
                                <motion.div className="row"
                                     variants={props.cVariants}
                                     initial="hidden"
                                     whileInView="show">

                                    <motion.div className="col-md-4 mb-3"
                                        variants={props.iVariants}>
                                        <div className="card border-0">

                                        <AdvancedVideo className='img-fluid card-img-top autoplay speakMain_img' 
                                                       cldVid={props.collabDig4} muted loop/>

                                            <div className="card-body">
                                                <h4 className="card-title">
                                                    Digital Design
                                                </h4>
                                            
                                            </div>
                                        </div>
                                    </motion.div>

                                    <motion.div className="col-md-4 mb-3"
                                        variants={props.iVariants}>
                                        <div className="card border-0">
                                        <AdvancedImage className='img-fluid card-img-top autoplay speakMain_img' 
                                                       cldImg={props.collabPort5} muted loop />
                                            <div className="card-body">
                                                <h4 className="card-title">
                                                   Panel Moderation and Discussions
                                                </h4>
                                               
                                            </div>
                                        </div>
                                    </motion.div>

                                    <div className="col-md-4 mb-3"
                                         variants={props.iVariants}>
                                        <div className="card border-0">
                        
                                            <div className="card-body">
                                                <h4 className="card-title">
                                                  
                                                </h4>
                                               
                                            </div>
                                        </div>
                                    </div>

                                </motion.div>
                            </div>
                            
                        </div>


            <button className="btn btn-outline-dark btn-lg border-3 rounded-pill"
                    href="#carouselExample"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next">
                Next
          </button>
                        

        </div>
    
    </div>
    </div>
</div>



      </div>
     
       
</div>

    );
  }
  
  export default CollaborateFor;