import ShortVideos from "./ShortVideos";
import NavBar from "../NavBar";
import Footer from "../Footer";

function VideoPage(props) {

    return (
  
      <div className="container-fluid-lg min-vh-100 mainBg center-text-div">

        <NavBar />

       <ShortVideos vMain1={props.vMain1}
                    v2Full={props.v2Full}
                    v1Full={props.v1Full}
                    v3Full={props.v3Full}
                    vMain3={props.vMain3}
                    loadViz={props.loadViz}
                    vVariants={props.vVariants}/>

       <Footer />
      </div>
 
    );
  }
  
  export default VideoPage;