import { Link } from "react-router-dom";
import {AdvancedVideo} from '@cloudinary/react';
import VideomainHeader from "../Spacers/VideomainHeader";
import { motion } from "framer-motion";


function VideoworkMain(props) { 
 

    return (
 
      <div className="container-fluid mainBg min-vh-100 center-text-div p-4">

        <VideomainHeader />
   
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-3">
          <div className="card" >
              <AdvancedVideo className='img-fluid card-img-top autoplay' 
                             cldVid={props.vMain1} 
                             autoPlay 
                             muted 
                             loop />
        </div>
    </div>

  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-3">
      <div className="card">
      <AdvancedVideo className='img-fluid card-img-top autoplay' 
                     cldVid={props.vMain3} 
                     autoPlay 
                     muted 
                     loop />
          
      </div>
  </div>


  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-3">
      <div className="card">
      <AdvancedVideo className='img-fluid card-img-top autoplay' 
                     cldVid={props.vMain2} 
                     autoPlay 
                     muted 
                     loop/>
      </div>
  </div>

  <div className="text-center p-5">
      <Link to="/multimedia">
        <motion.button className='btn btn-outline-light btnLight btn-lg border-3 rounded-pill'
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.3}}
                transition={{bounceDamping: 5}}>
                See work
        </motion.button>
      </Link>
        </div>
    </div>
  </div>
 
      
    );
  }
  
  export default VideoworkMain;