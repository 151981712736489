import { motion } from "framer-motion";

function Values(props) {

    return (
 
    <div className="container-fluid min-vh-100"> 

        <motion.div className="row pt-5 pb-2" 
              variants={props.cVariants}
              initial="hidden"
              whileInView="show">

  <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-2" 
              variants={props.iVariants}>

      <div className="values-blue-sq center-text-div p-2">
        <h2 className="">Visionary</h2>
        <br/>
        <h6 className="text-center">Imagine big. Dream big. Employ grit to make it happen and innovate.</h6>
      </div>

  </motion.div>

  <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-2" 
              variants={props.iVariants}>

      <div className="values-org-sq center-text-div p-2">
        <h2 className="">Transparency</h2>
        <br/>
        <h6 className="text-center">Honest communication to ensure your needs are being met - so we're on the same page to avoid hiccups down the road!</h6>
      </div>
  </motion.div>

  <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-2" 
              variants={props.iVariants}>

      <div className="values-mag-sq center-text-div p-2">
        <h2 className="">Excellence</h2>
        <br/>
        <h6 className="text-center">Creating impactful work we'll both be proud of.</h6>
      </div>
  </motion.div>

  </motion.div>


  <motion.div className="row pb-3 pt-5"
              variants={props.cVariants}
              initial="hidden"
              whileInView="show">

  <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-2"
              variants={props.iVariants}>
      <div className="values-mag-sq center-text-div p-2">
        <h2 className="">Curiousity</h2>
        <br/>
        <h6 className="text-center">Constantly on the hunt to learn something new and gain perspective. It bleeds into my creativity.</h6>
      </div>

  </motion.div>


  <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-2" 
              variants={props.iVariants}>

      <div className="values-purp-sq center-text-div p-2">
        <h2 className="">Tenacity</h2>
        <br/>
        <h6 className="text-center">Getting things done whether by research, experimentation, or getting scrappy.</h6>
    </div>

  </motion.div>

  <motion.div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 center-text-div p-2" 
              variants={props.iVariants}>

      <div className="values-blue-sq center-text-div p-2">
        <h2 className="">Empathy</h2>
        <br/>
        <h6 className="text-center">Making others feel seen and heard. Kindness, inclusivity, and compassion goes a long way.</h6>
    </div>

  </motion.div>
  
</motion.div>
</div>
       
    );
  }
  
  export default Values;