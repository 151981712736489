import Typewriter from 'typewriter-effect';

function VideomainHeader() {


    return (
 
        <div className="container-fluid intersect-title p-5">

            
          <h1 className="text-white text-center mainFont-header pt-5 pb-5"> 
                       Creating visuals you can 

                <Typewriter onInit={(typewriter) => {
        typewriter.typeString('<span style="color: magenta;">absorb.</span>')
          .callFunction(() => {
          })
          .pauseFor(2500)
          .deleteAll()
          .typeString('<span style="color: orange;">feel.</span>')
          .pauseFor(2500)
          .deleteAll()
          .typeString('<span style="color: blue;">taste.</span>')
          .pauseFor(2500)
          .deleteAll()
          .typeString('<span style="color: violet;">hear.</span>')
          .pauseFor(2500)
          .deleteAll()
          .callFunction(() => {
          })
          .start();
      }}
        options={{
          autoStart: true,
          loop: true,
        }}
      />
            </h1>

        </div>
    );
  }
  
  export default VideomainHeader;