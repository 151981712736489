import React, { useState } from 'react';
import jsonp from 'jsonp';

function NewsletterForm() {
  const [email, setEmail] = useState('');
  // const [loading, setLoading] = useState(false); 

  const newsletterSignup = e => {
    e.preventDefault();
    // setLoading(true);
    const url = process.env.REACT_APP_MCP_URL;
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, { msg }) => {
    alert(msg);
    setEmail('');
      // setLoading(false);
    },
    (error) => {
      console.log('FAILED...', error.text, error);
      setEmail('');
    });
  };

    return (
 
     <div className='container-fluid my-auto center-text-div'>

    <form className='row g-3 text-white' onSubmit={newsletterSignup}>
     

    <div className="col-lg-8 col-md-12 text-center">
            <input type="email" 
                   name="EMAIL" 
                   className="form-control input input-fluid" 
                   required value={email}    
                   placeholder="Subscribe" 
                   id="standard-basic"
                   margin="dense"
                   style={{ border:'3px solid', borderColor: 'white', background: 'transparent', color: 'white'}}
                   onChange={e => setEmail(e.target.value)}/>
                     </div>



          <div className="col-lg-4 col-md-12 text-center">
            <button type="submit" 
                     // isLoading={loading}
                     className='btn btn-outline-light btnLight btn-md border-3 rounded-pill'
                     value="Sign up">
                       Sign up
            </button> 
          </div>
            
    </form>
</div>



    );
  }
  
  export default NewsletterForm;