import { motion } from "framer-motion";

function AboutHeader() {
  
    return (
 
        <div className="container-fluid min-vh-100 center-text-div mainBg">

            <motion.h2 className="values-header" 
                       initial={{ opacity:0 }} 
                       whileInView={{ opacity:1 }} 
                       transition={{ duration: 0.9, delay: 0.1 }}>
                Meet SMERSIVE
            </motion.h2>
            <motion.span  
                        style={{color: 'white', fontStyle: 'italic'}}>
                pronounced Smer-sive
            </motion.span>

        </div>
    );
  }
  
  export default AboutHeader;