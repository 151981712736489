import { AdvancedImage} from '@cloudinary/react';
// import MeetSFHome from "./LandingHomePages/MeetSFhome";
import SpeakingMain from "./LandingHomePages/SpeakingMain";
import Footer from "./Footer";
import IntersectionMain from "./LandingHomePages/IntersectionMain";
import VideoworkMain from "./LandingHomePages/VideoworkMain";
import LandingVisual from "./LandingHomePages/LandingVisual";
import WhiteBreak from "./Spacers/WhiteBreak";
import NavBar from "./NavBar";
import { useState, useEffect } from "react";



function Home(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }, [])


    return (
      <div className="container-fluid-lg bg-dark">

      { loading ? 
      
      <div className="spinner-wrapper">
        <AdvancedImage className='img-fluid' cldImg={props.loadViz} />
        <span className="visually-hidden">Loading...</span>
        </div> 
        :

      <div className="container-fluid-lg bg-dark">

      <LandingVisual landingViz={props.landingViz}
                     iVariants={props.iVariants}/>
      

      <NavBar />

      {/* <MeetSFHome /> */}

      <VideoworkMain vMain1={props.vMain1} 
                     vMain2={props.vMain2} 
                     vMain3={props.vMain3}/>

      <SpeakingMain speakMainImg={props.speakMainImg}
                    vVariants={props.vVariants}/> 

      <IntersectionMain cVariants={props.cVariants}
                        iVariants={props.iVariants}/>

      <WhiteBreak />  

      <Footer /> 

      </div> }

     </div>
 
     
    );
  }
  
  export default Home;